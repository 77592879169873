import React, { useEffect } from "react";
import { format } from "date-fns";
import { trackPageView } from "../vendor/mixpanel";

const time = "1727374989";
const commit = "e93c5ff734fbb49a155e7088363c2d4b1116768a";
const readableLog = `\
`;

export function Version() {
    const date = new Date(parseInt(time) * 1000);
    useEffect(() => {
        trackPageView("version_info", { commit, time });
    }, []);
    return (
        <div>
            <div className="toolbar">
                <div className="logo">
                    <img alt="IMGA+ Logo" src="/logo.svg" />
                </div>
            </div>
            <div className="d-flex flex-col align-items-center" style={{ width: "100%" }}>
                <br />
                <h2>IMG+ App Version Info</h2>
                <br />
                <br />
                <div>
                    <span className="primary">Built At: </span>
                    <span className="secondary">{format(date, "MMMM dd, yyyy hh:mm a")}</span>
                </div>
                <br />
                <div>
                    <span className="primary">Commit: </span>
                    <span className="secondary">{commit}</span>
                </div>
                <br />
                <div>Log:</div>
                <div className="secondary" style={{ whiteSpace: "pre" }}>
                    {readableLog}
                </div>
                <br />
            </div>
        </div>
    );
}
